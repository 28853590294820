import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Validator from "validator";
import { blueprintSubcriptionEvent } from "./FacebookEventTracking";

const BlueprintCaptureForm = () => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [blueprintSent, setBlueprintSent] = useState(false);

  const addUserToList = async () => {
    const data = { name, email, surname, whatsapp };
    if (!name || !surname || !email || !whatsapp) {
      window.toastr.error(t("2025_blueprint_please_fill_all_fields"));
      return;
    }
    if (!Validator.isEmail(email)) {
      window.toastr.error(t("2025_blueprint_invalid_email"));
      return;
    }
    if (!Validator.isMobilePhone(whatsapp.replace(/\D/g, ""), "any")) {
      window.toastr.error(t("2025_blueprint_invalid_whatsapp"));
      return;
    }
    try {
      const response = await axios.put("/api/contacts/blueprint", data);
      setName("");
      setSurname("");
      setEmail("");
      setWhatsapp("");
      response?.data?.message === "2025_blueprint_sent"
        ? window.toastr.success(t(response?.data?.message))
        : window.toastr.error(t(response?.data?.message));
      console.log("response", response.data);
      setBlueprintSent(true);
      blueprintSubcriptionEvent();
    } catch (error) {
      console.log("erro", error);
      window.toastr.error(t("2025_blueprint_submission_error"));
    }
  };

  const imageSrc =
    i18n.language === "pt-BR"
      ? "images/blueprintmockup_ptBR.png"
      : "images/blueprintmockup_en.png";

  return (
    <div className="flex md:h-screen h-full text-white flex-col bg-gradient-radial to-vulcan from-leanit101Blue font-Barlow">
      <div className="flex md:flex-row flex-col justify-between items-center h-full py-4">
        <div className="flex flex-col 2xl:flex-row justify-around md:px-24 px-3 w-full items-center">
          <div className="md:mt-4 mt-20 flex items-center flex-col">
            <span className="md:text-5xl text-2xl break-words text-start md:pt-6 font-black md:max-w-[700px] drop-shadow-2xl">
              {t("2025_blueprint_heading")}
            </span>
            <div className="md:w-[700px] self-center flex p-4">
              <img src={imageSrc} alt="Blueprint Mockup" />
            </div>
          </div>
          {!blueprintSent ? (
            <div className="p-[1px] mx-4 my-6 w-full rounded-3xl shadow-black shadow-lg bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1">
              <div className="flex flex-col text-left gap-3 h-fit md:w-full bg-vulcan drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-3xl md:p-12 p-7">
                <span className="text-3xl font-black">
                  {t("2025_blueprint_subheading")}
                </span>
                <span className="text-base">
                  {t("2025_blueprint_description")}
                </span>
                <div className="text-w">
                  <form className="flex flex-col text-white gap-4">
                    <div className="flex flex-col font-semibold text-sm">
                      {t("2025_blueprint_name")}
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="rounded-md p-2 text-black mt-1"
                        placeholder={t("2025_blueprint_name_placeholder")}
                      />
                    </div>
                    <div className="flex flex-col font-semibold text-sm">
                      {t("2025_blueprint_surname")}
                      <input
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        className="rounded-md p-2 text-black mt-1"
                        placeholder={t("2025_blueprint_surname_placeholder")}
                      />
                    </div>
                    <div className="flex flex-col font-semibold text-sm">
                      {t("2025_blueprint_email")}
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="rounded-md p-2 text-black mt-1"
                        placeholder={t("2025_blueprint_email_placeholder")}
                      />
                    </div>
                    <div className="flex flex-col font-semibold text-sm">
                      {t("2025_blueprint_whatsapp")}
                      <input
                        type="text"
                        value={whatsapp}
                        onChange={(e) =>
                          setWhatsapp(e.target.value.replace(/\D/g, ""))
                        }
                        className="rounded-md p-2 text-black mt-1"
                        placeholder={t("2025_blueprint_whatsapp_placeholder")}
                      />
                    </div>
                    <input
                      type="button"
                      className="text-white bg-cyan-600 hover:bg-cyan-500 font-black rounded-full w-full mt-5 p-3 px-4 self-center"
                      value={t("2025_blueprint_submit_button")}
                      onClick={addUserToList}
                    />
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-left gap-3 md:w-[600px] h-fit bg-vulcan drop-shadow-2xl border-1 border-solid border-pyramid-19 rounded-xl md:p-12 p-7">
              <span className="text-3xl font-black">
                {t("2025_blueprint_thank_you")}
              </span>
              <span className="text-lg py-5">
                {t("2025_blueprint_success_message")}
              </span>
              <button
                className="text-white bg-cyan-600 hover:bg-cyan-500 font-black rounded-full w-fit p-5 text-xl px-12 self-center"
                type="button"
                onClick={() => (window.location.href = "/")}
              >
                {t("2025_blueprint_next_step_button")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlueprintCaptureForm;
