import { useTranslation } from "react-i18next";
import "../input.css";

const AboutUsPage = () => {
  const { t } = useTranslation();

  const WhyItem = ({ title, description, id }) => {
    return (
      <div
        id={`why-card-${id}`}
        className="relative justify-center shadow-black shadow-lg flex flex-col flex-shrink-0 w-[75%] md:w-1/6 m-3 p-[1px] rounded-3xl border border-pyramid-19"
      >
        <div
          className="relative flex flex-col bg-steelGray bg-opacity-50 rounded-3xl h-full w-full text-left sm:px-9 sm:py-8 p-4"
          style={{
            backgroundImage: `radial-gradient(rgba(11, 166, 218, 0.1), transparent))`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        >
          <span className="font-bold text-md text-white mb-4">{title}</span>
          <span className="text-sm text-gray-300">{description}</span>
        </div>
      </div>
    );
  };

  const whyContent = [
    {
      title: t("2025_aboutUs_why1_title"),
      description: t("2025_aboutUs_why1_description"),
    },
    {
      title: t("2025_aboutUs_why2_title"),
      description: t("2025_aboutUs_why2_description"),
    },
    {
      title: t("2025_aboutUs_why3_title"),
      description: t("2025_aboutUs_why3_description"),
    },
    {
      title: t("2025_aboutUs_why4_title"),
      description: t("2025_aboutUs_why4_description"),
    },
  ];

  const whySection = (
    <div
      id="why-section"
      className="relative mt-10 flex flex-col md:gap-6 gap-4 w-full bg-vulcan"
    >
      <div className="relative w-full h-full">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src="/images/why.mp4"
          autoPlay
          playsInline
          loop
          muted
        ></video>
        <div className="relative md:h-44 z-10 bg-steelGray bg-opacity-0 flex items-center justify-center flex-col md:gap-5 gap-2 w-full">
          <span className="text-center md:text-5xl text-2xl font-black break-words p-4 py-8">
            {t("2025_aboutUs_why_title")}
          </span>
        </div>
        <div className="">
          {/* Cards Section */}
          <div className="relative flex flex-row md:justify-center md:flex-wrap items-center gap-2 overflow-x-scroll md:overflow-visible no-scrollbar">
            {whyContent.map((item, id) => (
              <WhyItem
                key={id}
                id={id}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>

          {/* Bottomline */}
          <div className="relative px-4 md:px-64 my-10 rounded-xl text-center text-gray-400">
            {t("2025_aboutUs_why4_bottomline")}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-vulcan flex flex-col md:py-28 gap-auto items-center  text-slate-200">
      <h1 className="text-4xl md:text-5xl font-extrabold text-center md:mt-8 md:mb-10 mt-24 mb-4">
        {t("2025_aboutUs_title")}
      </h1>
      <div className="flex flex-col md:flex-row md:mx-20 mx-4">
        <div className="p-[1px] mx-4 mt-6 rounded-3xl shadow-black shadow-lg bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1">
          <header className="relative w-full md:sticky h-full text-center drop-shadow-2xl flex flex-col bg-steelGray rounded-3xl md:p-6 p-4">
            {/* Radial Gradient */}
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{
                background:
                  "radial-gradient(circle at center, rgba(11, 166, 218, 0.3), transparent)",
                zIndex: 0,
                opacity: 1, // Full opacity for the radial gradient
              }}
            ></div>

            {/* Background Image */}
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{
                backgroundImage: 'url("/images/PyramidIcons/3.png")',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center", // Ensure image is centered both horizontally and vertically
                backgroundSize: "50%", // Image is 90% of container size
                zIndex: 0,
                opacity: 0.1, // Reduced opacity for the image
              }}
            ></div>

            {/* Text Layer */}
            <div className="text-white">
              <p className="text-lg mb-6 text-center md:text-center">
                {t("2025_aboutUs_introduction1")}
              </p>
            </div>
          </header>
        </div>
        <div className="p-[1px] mx-4 mt-6 rounded-3xl shadow-black shadow-lg  bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1">
          <header className="relative w-full md:sticky h-full text-center drop-shadow-2xl flex flex-col bg-steelGray rounded-3xl md:p-6 p-4">
            {/* Radial Gradient */}
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{
                background:
                  "radial-gradient(circle at center, rgba(11, 166, 218, 0.3), transparent)",
                zIndex: 0,
                opacity: 1, // Full opacity for the radial gradient
              }}
            ></div>

            {/* Background Image */}
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{
                backgroundImage: 'url("/images/PyramidIcons/16.png")',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center", // Ensure image is centered both horizontally and vertically
                backgroundSize: "40%", // Image is 90% of container size
                zIndex: 0,
                opacity: 0.1, // Reduced opacity for the image
              }}
            ></div>

            {/* Text Layer */}
            <div className="text-white">
              <p className="text-lg mb-6 text-center md:text-center">
                {t("2025_aboutUs_introduction2")}
              </p>
            </div>
          </header>
        </div>
        <div className="p-[1px] mx-4 mt-6 rounded-3xl shadow-black shadow-lg  bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1">
          <header className="relative w-full md:sticky h-full text-center drop-shadow-2xl flex flex-col bg-steelGray rounded-3xl md:p-6 p-4">
            {/* Radial Gradient */}
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{
                background:
                  "radial-gradient(circle at center, rgba(11, 166, 218, 0.3), transparent)",
                zIndex: 0,
                opacity: 1, // Full opacity for the radial gradient
              }}
            ></div>

            {/* Background Image */}
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{
                backgroundImage: 'url("/images/PyramidIcons/17.png")',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center", // Ensure image is centered both horizontally and vertically
                backgroundSize: "70%", // Image is 90% of container size
                zIndex: 0,
                opacity: 0.1, // Reduced opacity for the image
              }}
            ></div>

            {/* Text Layer */}
            <div className="text-white">
              <p className="text-lg text-center md:text-center">
                {t("2025_aboutUs_introduction3")}
              </p>
            </div>
          </header>
        </div>
      </div>

      <div className="px-4 text-xl">
        <p className="mt-20">
          <span className="font-semibold text-white">
            {t(`2025_aboutUs_purpose_title`)}
          </span>
          {t(`2025_aboutUs_purpose_description`)}
        </p>
        <p className="mt-4">
          <span className="font-semibold text-white">
            {t(`2025_aboutUs_mission_title`)}
          </span>
          {t(`2025_aboutUs_mission_description`)}
        </p>
        <p className="mt-4">
          <span className="font-semibold text-white">
            {t(`2025_aboutUs_vision_title`)}
          </span>
          {t(`2025_aboutUs_vision_description`)}
        </p>
      </div>
      <div className="md:mx-10 mx-4">
        <img
          src="images/collage.png"
          alt="Samuel in action"
          className="rounded-xl drop-shadow-xl"
        />
      </div>

      <div className="md:px-36 p-4 flex flex-col items-center text-left justify-center md:flex-row gap-6">
        <div className="rounded-full p-[1px] bg-gradient-to-r from-pyramid-19 via-pyramid-1 to-pyramid-10 drop-shadow-xl h-fit w-full md:w-1/3">
          <img
            src="images/samuel.jpg"
            alt="Samuel Crescêncio"
            className="rounded-full "
          />
        </div>
        <div className="md:w-full p-4 md:ml-4">
          <h1 className="text-4xl md:text-5xl font-extrabold pb-16">
            {t("2025_aboutUs_leadership")}
          </h1>
          <h3 className="text-2xl font-semibold">
            {t("2025_aboutUs_leadershipDetails_samuelTitle")}
          </h3>
          <p className="text-lg mt-4">
            {t("2025_aboutUs_leadershipDetails_samuelBio1")}
          </p>
          <p className="text-lg mt-4">
            {t("2025_aboutUs_leadershipDetails_samuelBio2")}
          </p>
          <p className="text-lg mt-4">
            {t("2025_aboutUs_leadershipDetails_samuelBio3")}
          </p>
        </div>
      </div>

      {whySection}

      <h2 className="text-3xl font-bold md:mt-10 md:px-24 p-4">
        {t("2025_aboutUs_experts")}
      </h2>
      <p className="text-lg mt-4 md:px-24 md:py-6 p-4">
        {t("2025_aboutUs_expertsDescription")}
      </p>
      <div className="flex flex-col justify-center gap-8 p-10">
        <p className={` text-center text-pyramid-1`}>{t("2025_aboutUs_cta")}</p>

        <button
          onClick={() =>
            window.open(
              "https://calendly.com/screscencio-leanit101/30min",
              "_blank"
            )
          }
          id="services-cta-button"
          className="flex justify-center"
        >
          <span
            className={`p-1 w-72 rounded-full shadow-black shadow-lg text-base text-gray-600 bg-pyramid-1 font-semibold hover:bg-pyramid-3 drop-shadow-lg`}
          >
            {t("2025_service_sustainability_cta_button")}
          </span>
        </button>
      </div>
    </div>
  );
};

export default AboutUsPage;
