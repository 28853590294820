import "../../input.css";

import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const [t] = useTranslation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.offsetTop;
      window.scrollTo({
        top: offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      id="hero-section"
      className="relative flex justify-between flex-col md:w-full md:h-screen overflow-hidden z-40"
    >
      {/* Fullscreen video background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/images/hero.mp4"
        autoPlay
        playsInline
        loop
        muted
      ></video>

      {/* Content overlay */}
      <div className="relative z-10 flex flex-col justify-center h-[60%] 2xl:flex-row w-full md:pt-36">
        <div className="flex 2xl:w-[800px] w-full px-3 flex-col font-bold sm:text-center md:pt-56 pt-36 items-center sm:p-3 mb-28">
          <span className="sm:text-5xl text-2xl font-bold">
            {t("2025_hero_headline")}
          </span>
          <span className="text-sm sm:text-2xl mt-4 italic px-8 md:px-2 font-medium">
            {t("2025_hero_tagline")}
          </span>
          <div className="mt-10 sm:flex-col sm:items-start justify-center hidden sm:flex">
            <button
              onClick={() => {
                scrollToSection("services-section");
              }}
              id="academy-button"
              className="flex md:justify-start justify-center"
              target="_blank"
            >
              <span
                className={`p-3 px-4 w-64 rounded-full text-2xl border-pyramid-1 border-solid border font-extrabold hover:bg-pyramid-16 drop-shadow-lg`}
              >
                {t("2025_learn_more")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
