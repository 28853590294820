import { useTranslation } from "react-i18next";
import "../../input.css";

const ApproachItem = ({ title, description, id }) => {
  //  bg-gradient-to-r from-pyramid-19 via-pyramid-10 to-pyramid-1"
  // url(\"data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Ctext x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='rgba(255,255,255,0.05)' font-family='ritheous, sans-serif' font-size='13rem' style='text-shadow: 20px 20px 50px rgba(0,0,0,1)'%3E${id + 1}%3C/text%3E%3C/svg%3E\")`,
  return (
    <div
      id={`approach-card-${id}`}
      className="relative shadow-black shadow-lg justify-center flex flex-col  w-[90%] md:w-1/5 m-3 p-[1px] rounded-3xl border border-pyramid-19"
    >
      <div
        className="relative flex flex-col bg-steelGray bg-opacity-50 rounded-3xl h-full w-full text-left sm:px-9 sm:py-8 p-6"
        style={{
          backgroundImage: `radial-gradient(rgba(11, 166, 218, 0.1), transparent))`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      >
        <span className="font-bold text-lg text-white mb-4">{title}</span>
        <span className="text-md text-gray-300">{description}</span>
      </div>
    </div>
  );
};

const ApproachSection = () => {
  const [t] = useTranslation();
  const approachContent = [
    {
      title: t("2025_approach1_title"),
      description: t("2025_approach1"),
    },
    {
      title: t("2025_approach2_title"),
      description: t("2025_approach2"),
    },
    {
      title: t("2025_approach3_title"),
      description: t("2025_approach3"),
    },
    {
      title: t("2025_approach4_title"),
      description: t("2025_approach4"),
    },
  ];

  return (
    <div
      id="approach-section"
      className="relative md:px-0 px-4 flex flex-col md:gap-6 gap-4 w-full bg-vulcan"
    >
      <div className="relative w-full h-full overflow-hidden rounded-xl">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src="/images/approach.mp4"
          autoPlay
          playsInline
          loop
          muted
        ></video>
        <div className="relative md:h-64 z-10 bg-steelGray bg-opacity-0 flex items-center justify-center flex-col md:p-5 md:gap-5 p-2 gap-2 w-full">
          <span className="text-center md:text-5xl text-2xl font-black break-words p-3">
            {t("2025_approach")}
          </span>
          <span className="md:text-xl text-white md:px-48 text-base pt-2">
            {t("2025_approach_description")}
          </span>
        </div>
        <div className="md:px-36">
          {/* Cards Section */}
          <div className="relative flex flex-col md:flex-row md:flex-wrap justify-center items-center gap-4 overflow-x-scroll md:overflow-visible no-scrollbar">
            {approachContent.map((item, id) => (
              <ApproachItem
                key={id}
                id={id}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>

          {/* Bottomline */}
          <div className="relative my-10 flex flex-col justify-center gap-4 rounded-xl text-center text-gray-400">
            <span className="px-4">{t("2025_approach_bottomline")}</span>

            <p className={`font-semibold px-4 text-center text-pyramid-1`}>
              {t("2025_aboutUs_cta")}
            </p>

            <button
              onClick={() =>
                window.open(
                  "https://calendly.com/screscencio-leanit101/30min",
                  "_blank"
                )
              }
              id="services-cta-button"
              className="flex justify-center"
            >
              <span
                className={`p-1 w-72 rounded-full shadow-black shadow-lg text-base text-gray-600 bg-pyramid-1 font-semibold hover:bg-pyramid-3 drop-shadow-lg`}
              >
                {t("2025_service_sustainability_cta_button")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproachSection;
