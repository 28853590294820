import { useTranslation } from "react-i18next";
import "../../input.css";

const Card = ({
  title,
  content,
  gradientClass,
  watermark,
  headline,
  color,
}) => {
  return (
    <div id={`stage-card-${watermark}`} className="relative h-full z-10">
      {/* Card Content */}
      <div
        className={`relative flex flex-col bg-steelGray rounded-3xl h-full w-full text-left sm:px-9 sm:py-8 p-6 ${gradientClass} shadow-black shadow-lg `}
        style={{
          backgroundImage: `radial-gradient(rgba(11, 166, 218, 0.1), transparent), 
            url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'%3E%3Ctext x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='rgba(255,255,255,0.05)' font-family='ritheous, sans-serif' font-size='13rem' style='text-shadow: 20px 20px 50px rgba(0,0,0,1)'%3E${watermark}%3C/text%3E%3C/svg%3E")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      >
        <span className="font-bold text-center text-2xl text-white mb-4">
          {title}
        </span>
        <p className={`font-semibold text-center text-${color}`}>{headline}</p>

        <hr
          className={`flex text-center w-full my-4 h-[5px] rounded-full ${gradientClass}`}
        />
        <div className="md:text-sm font-regular text-slate-200">{content}</div>
      </div>
    </div>
  );
};

const ServicesSection = () => {
  const [t] = useTranslation();

  const cardContent1 = (
    <div className="flex flex-col space-y-3">
      <p className="text-gray-300">
        {t("2025_service_foundational_description")}
      </p>
      <p className="font-semibold">
        {t("2025_service_foundational_activities")}
      </p>
      <ul className="pl-4 list-inside space-y-3 text-gray-300">
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_foundational_activity1_title")}
          </span>
          {t("2025_service_foundational_activity1")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_foundational_activity2_title")}
          </span>
          {t("2025_service_foundational_activity2")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_foundational_activity3_title")}
          </span>
          {t("2025_service_foundational_activity3")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_foundational_activity4_title")}
          </span>
          {t("2025_service_foundational_activity4")}
        </li>
      </ul>
      <p className="font-semibold">{t("2025_service_foundational_benefits")}</p>
      <ul className="pl-4 list-inside space-y-3 text-gray-300">
        <li>{t("2025_service_foundational_benefit1")}</li>
        <li>{t("2025_service_foundational_benefit2")}</li>
        <li>{t("2025_service_foundational_benefit3")}</li>
      </ul>
      <p className="font-semibold">{t("2025_service_foundational_duration")}</p>
      <p className={`font-semibold text-center text-cyan-300`}>
        {t("2025_service_foundational_cta")}
      </p>

      <button
        onClick={() =>
          (window.location.href =
            "https://wa.me/5548996179594?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20da%20Lean%20it%20101")
        }
        id="services-cta-button"
        className="flex justify-center"
        target="_blank"
      >
        <span
          className={`p-1 w-72 shadow-black shadow-lg rounded-full text-base bg-pyramid-17 font-semibold hover:bg-pyramid-19 drop-shadow-lg`}
        >
          {t("2025_service_foundational_cta_button")}
        </span>
      </button>
    </div>
  );

  const cardContent2 = (
    <div className="flex flex-col space-y-3">
      <p className="text-gray-300">
        {t("2025_service_customization_description")}
      </p>
      <p className="font-semibold">
        {t("2025_service_customization_activities")}
      </p>
      <ul className="pl-4 list-inside space-y-3 text-gray-300">
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_customization_activity1_title")}
          </span>
          {t("2025_service_customization_activity1")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_customization_activity2_title")}
          </span>
          {t("2025_service_customization_activity2")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_customization_activity3_title")}
          </span>
          {t("2025_service_customization_activity3")}
        </li>
      </ul>
      <p className="font-semibold">
        {t("2025_service_customization_benefits")}
      </p>
      <ul className="pl-4 list-inside space-y-3 text-gray-300">
        <li>{t("2025_service_customization_benefit1")}</li>
        <li>{t("2025_service_customization_benefit2")}</li>
        <li>{t("2025_service_customization_benefit3")}</li>
        <li>{t("2025_service_customization_benefit4")}</li>
        <li>{t("2025_service_customization_benefit5")}</li>
      </ul>
      <p className="font-semibold">
        {t("2025_service_customization_duration")}
      </p>
      <p className={`font-semibold text-center text-pyramid-10`}>
        {t("2025_service_customization_cta")}
      </p>

      <button
        onClick={() => (window.location.href = "/blueprint")}
        id="services-cta-button"
        className="flex justify-center"
        target="_blank"
      >
        <span
          className={`p-1 w-72 rounded-full shadow-black shadow-lg  text-base bg-pyramid-10 font-semibold hover:bg-pyramid-12 drop-shadow-lg`}
        >
          {t("2025_service_customization_cta_button")}
        </span>
      </button>
    </div>
  );

  const cardContent3 = (
    <div className="flex flex-col space-y-3">
      <p className="text-gray-300">
        {t("2025_service_sustainability_description")}
      </p>
      <p className="font-semibold">
        {t("2025_service_sustainability_activities")}
      </p>
      <ul className="pl-4 list-inside space-y-3 text-gray-300">
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_sustainability_activity1_title")}
          </span>
          {t("2025_service_sustainability_activity1")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_sustainability_activity2_title")}
          </span>
          {t("2025_service_sustainability_activity2")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_sustainability_activity3_title")}
          </span>
          {t("2025_service_sustainability_activity3")}
        </li>
        <li>
          <span className="text-white font-semibold">
            {t("2025_service_sustainability_activity4_title")}
          </span>
          {t("2025_service_sustainability_activity4")}
        </li>
      </ul>
      <p className="font-semibold">
        {t("2025_service_sustainability_benefits")}
      </p>
      <ul className="pl-4 list-inside space-y-3 text-gray-300">
        <li>{t("2025_service_sustainability_benefit1")}</li>
        <li>{t("2025_service_sustainability_benefit2")}</li>
        <li>{t("2025_service_sustainability_benefit3")}</li>
        <li>{t("2025_service_sustainability_benefit4")}</li>
      </ul>
      <p className="font-semibold">
        {t("2025_service_sustainability_duration")}
      </p>
      <p className={`font-semibold text-center text-pyramid-1`}>
        {t("2025_service_sustainability_cta")}
      </p>

      <button
        onClick={() =>
          window.open(
            "https://calendly.com/screscencio-leanit101/30min",
            "_blank"
          )
        }
        id="services-cta-button"
        className="flex justify-center"
      >
        <span
          className={`p-1 w-72 rounded-full shadow-black shadow-lg text-base text-gray-600 bg-pyramid-1 font-semibold hover:bg-pyramid-3 drop-shadow-lg`}
        >
          {t("2025_service_sustainability_cta_button")}
        </span>
      </button>
    </div>
  );

  return (
    <div
      id="services-section"
      className="bg-ebony flex h-full flex-col py-16 z-0"
    >
      <div className="flex flex-col justify-center md:pb-16">
        <span className="text-center md:text-5xl text-2xl font-black break-words p-3">
          {t("2025_service_headline")}
        </span>
        <span className="md:text-xl text-gray-400 text-base pt-2">
          {t("2025_service_tagline")}
        </span>
      </div>
      <div className="flex flex-col lg:flex-row md:px-10 sm:px-12">
        <div className="p-[1px] lg:w-1/3 m-3 rounded-3xl drop-shadow-xl bg-gradient-to-r from-pyramid-19 to-gray-700">
          <Card
            title={t("2025_service_foundational")}
            headline={t("2025_service_foundational_headline")}
            content={cardContent1}
            gradientClass="bg-gradient-to-r from-pyramid-19 to-gray-700"
            watermark="1"
            color="cyan-300"
          />
        </div>
        <div className="p-[1px] lg:w-1/3 m-3 rounded-3xl drop-shadow-xl bg-gradient-to-r from-pyramid-10 to-gray-700">
          <Card
            title={t("2025_service_customization")}
            headline={t("2025_service_customization_headline")}
            content={cardContent2}
            gradientClass="bg-gradient-to-r from-pyramid-10 to-gray-700"
            watermark="2"
            color="pyramid-10"
          />
        </div>
        <div className="p-[1px] lg:w-1/3 m-3 rounded-3xl drop-shadow-xl bg-gradient-to-r from-pyramid-1 to-gray-700">
          <Card
            title={t("2025_service_sustainability")}
            headline={t("2025_service_sustainability_headline")}
            content={cardContent3}
            gradientClass="bg-gradient-to-r from-pyramid-1 to-gray-700"
            watermark="3"
            color="pyramid-1"
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
