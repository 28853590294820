import React from "react";
import { useTranslation } from "react-i18next";

const CustomerLogosSection = () => {
  const { t } = useTranslation();

  // List of logo filenames
  const logos = [
    "logo_vale.png",
    "logo_inside.png",
    "logo_softplan.png",
    "logo_ambevtech.png",
    "logo_solides.png",
    "logo_contele.png",
    "logo_prs.png",
    "logo_segware.png",
    "logo_solarview.png",
    "logo_ndd.png",
    "logo_blueticket.png",
    "logo_hbsis.png",
    "logo_stormtech.png",
  ];

  return (
    <section className="py-14 bg-slate-100">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-center md:text-5xl text-2xl text-gray-700 font-black break-words mb-4">
          {t("2025_logo_clients_headline")}
        </h2>
        <h3 className="md:text-xl text-gray-400 text-base pb-4 px-4">
          {t("2025_logo_clients_tagline")}
        </h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8 items-center">
          {logos.map((logo, index) => (
            <div key={index} className="flex items-center justify-center">
              <img
                src={`/images/logo_clients/${logo}`}
                alt={logo
                  .replace("logo_", "")
                  .replace(".png", "")
                  .toUpperCase()}
                className="max-h-16 object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomerLogosSection;
